import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    messageId: Number,
  };
  static targets = ["count", "container", "messageBody"];

  connect() {
    this.#scroll();
    this.dispatch("connected", { detail: { content: this } });
  }

  /**
   * 既読ラベルの設定, 閲覧者数が1の場合は数値は出さない
   * @param {Integer} count 既読数
   * @param {Integer} members 閲覧者数
   */
  setReadCount(count, members) {
    if (!this.hasCountTarget) return;
    if (!count) return;

    this.countTarget.classList.add("opened");
    // 1on1 なら数字いらない
    if (members != 1) {
      this.countTarget.textContent = count;
    }
  }

  setOwned() {
    this.containerTarget.classList.add("me");
    this.messageBodyTarget.classList.remove("receive");
    this.messageBodyTarget.classList.add("give");
  }

  #scroll() {
    const rect = this.element.getBoundingClientRect();
    // 描画領域外に要素が追加された場合は要素の高さ分スクロール. マージン分考慮する. TODO: 日付ラベルが追加される場合
    if (
      document.body.scrollHeight - window.scrollY - window.innerHeight <
      rect.height + 32
    ) {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  }
}
